<template>
  <div>
    <div v-if="type === 'dropdown'">
      <small>{{type}}:{{modelId}}</small>
    </div>

    <div v-if="type === 'radio'">
      <check-box @change="updateActive"
                 :label="label"
                 v-model="active"
                 class="btn btn-accordion d-flex justify-content-between align-items-center btn-primary btn-block collapsed noMargin mb-0"
                 type-radio
                 :radio-permaselect="selectionRevokable"
                 css-checkmark>
      </check-box>
    </div>
    <div v-if="type === 'checkbox'">
      <check-box @change="updateActive"
                 :label="label"
                 v-model="active"
                 class="btn btn-accordion d-flex justify-content-between align-items-center btn-primary btn-block collapsed noMargin mb-0"
                 css-checkmark>
      </check-box>
    </div>
    <div v-if="type === 'checkbox-boxed'">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <check-box @change="updateActive"
                     :label="label"
                     v-model="active"
                     css-checkmark
                     class="btn btn-accordion d-flex justify-content-between align-items-center btn-primary btn-block collapsed noMargin mb-0">
          </check-box>
        </b-card-header>
        <b-collapse :visible="active" role="tabpanel" class="btnControlledAccordeonBody">
          <b-card-body class="">
            <!-- Check if longDesc exists -->

            <StructureEditGroup
              v-for="child of structure[structureSettings.childPropNode]"
              :key="child[structureSettings.idPropGroup]"
              v-model="nativeModel"
              :path="modelId"
              :structure="child"
              :structure-settings="structureSettings"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div v-if="type === 'radio-boxed'">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <check-box
            @change="updateActive"
            :label="label"
            v-model="active"
            css-checkmark
            type-radio
            :radio-permaselect="selectionRevokable"
            class="btn btn-accordion d-flex justify-content-between align-items-center btn-primary btn-block collapsed noMargin mb-0">
          </check-box>
        </b-card-header>
        <b-collapse :visible="active" role="tabpanel" class="btnControlledAccordeonBody">
          <b-card-body class="">
            <!-- Check if longDesc exists -->

            <StructureEditGroup
              v-for="child of structure[structureSettings.childPropNode]"
              :key="child[structureSettings.idPropGroup]"
              v-model="nativeModel"
              :path="modelId"
              :structure="child"
              :structure-settings="structureSettings"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

  </div>
</template>

<script>
import CheckBox from "@/components/sub-components/CheckBox.vue";

export default {
  data(){
    return {
      active: false,
    };
  },
  components: {
    StructureEditGroup: () => import("@/components/layout-components/StructureEditGroup.vue"),
    CheckBox
  },
  name: 'StructureEditNode',
  event: ['update:modelValue'],
  model: { // vue 2 v-model
    event: 'update:modelValue',
    prop: 'modelValue',
  },
  props: {
    id: String,
    path: {
      Type: String,
      default: () => '',
    },
    // /** @type EditableRecursiveDataStructure */
    /** @type {ProductDetailItemNode} */
    structure: {
      Type: Object | 'ProductDetailItemNode',
      // default: () => {}
      required: true,
    },
    modelValue: {
      Type: Array,
      default: () => [],
    },
    /** @type {'checkbox' | 'checkbox-boxed' | 'radio' | 'radio-boxed' | 'dropdown' | string} */
    type: {
      Type: String,
      validator: (value) => ['checkbox', 'checkbox-boxed', 'radio', 'radio-boxed', 'dropdown'].includes(value),
    },
    selectionRevokable: Boolean,
    label: {
      Type: String,
      required: true,
    },
    /** @type {StructureEditSettings} */
    structureSettings: {
      Type: Object || 'StructureEditSettings',
      required: true,
    },
  },
  computed: {
    nativeModel: {
      /** @return {Array<String>} */
      get(){return this.modelValue;},
      set(value){this.$emit('update:modelValue', value);},
    },
    modelId(){
      return this.path + '.' + this.structure[this.structureSettings.idPropNode];
    },
    config(){
      return window.config;
    },
  },
  methods:{
    // region Handlers
    updateActive(value) { // event handle
      this.active = value;

      if (value) {
        this.selectSelf();
      } else {
        this.deselect(true);
      }
    },
    updateSelection(){
      const currentModelSelectionChilds = this.getMeAndChildren(true);
      this.active = currentModelSelectionChilds.includes(this.modelId);
    },
    deselect(withSelf=false){
      const modelId = this.modelId;

      this.nativeModel = this.nativeModel
        .filter(b => !((b === modelId && withSelf) || b.startsWith(modelId + '.')));
    },
    selectSelf(){
      let out = this.nativeModel;
      if (this.type === 'radio' || this.type === 'radio-boxed') {
        // this.deselectSelf();
        const parentId = this.path;

        out = out
          .filter(b => !b.startsWith(parentId + '.'));
      }

      out.push(this.modelId);
      this.nativeModel = out;
    },
    // endregion
    /** @return Array<String> */
    getMeAndChildren(onlyDirect=true){
      const modelId = this.modelId;
      return this.nativeModel
        .map(a=>""+a)
        .filter(a=>a===modelId || a.startsWith(modelId+'.'))
        .filter(a=>!onlyDirect||!a.replace(modelId,'').includes('.',1))
    },
  },
  watch:{
    'nativeModel': function (){
      this.updateSelection();
    },
  },
}
</script>

<style lang="scss">
@import '@/css/customizer';

.btn-primary .checkmark { // since blue on blue does not work that well
  --focus-color: var(--background-color);
}
</style>
